import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Layout,
  Typography,
  Card,
  Modal,
  Divider,
  Button,
} from "antd";
import "./customerCart.css";
import { useHistory } from "react-router-dom";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useCarts, clearCarts } from "../../utility";
import { IItemCart, ItemCart } from "../../models/ItemCart";
import { cfs } from "../../firebase/firebase";
import firebase from "firebase/app";
import { useParams } from "react-router-dom";
import { IMerchant } from "../../models/merchant";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/cooking.json";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import { IChoiceValue } from "../../models/choiceValue";
import { numberWithCommas } from "../../utility";
// import axios from 'axios'
// import querystring from 'querystring'
interface ParamTypes {
  idMerchant: string;
  tableNumber: string;
}
const { Header, Content } = Layout;
const { Title } = Typography;

function CustomerCart() {
  const [Success, setSuccess] = useState(false);
  const [isCreateOrder, setIsCreateOrder] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  let { idMerchant, tableNumber } = useParams<ParamTypes>();
  const history = useHistory();
  const [carts, setCarts] = useCarts<Array<IItemCart>>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (idMerchant) {
      cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .get()
        .then((doc: any) => {
          let tmpCurrentUser: any = doc.data();
          if (
            Number(tableNumber) >= 0 &&
            Number(tableNumber) <= Number(tmpCurrentUser.maxTable)
          ) {
            document.title = `${tmpCurrentUser.merchantNameTH} | ${tmpCurrentUser.merchantNameEN}`;
            setCurrentUser(tmpCurrentUser);
          } else {
            history.push("/");
          }
          // setProducts(pros);
        })
        .catch((e) => console.log(e));
    }
  }, [idMerchant]);

  const handleClickOpen = () => {
    setSuccess(false);
    clearCarts();
    history.push(`/${idMerchant}/${tableNumber}`);
  };

  const removeItem = (index: number) => {
    let items = [...carts];
    items.splice(index, 1);
    setCarts(items);
  };

  const createOrder = async () => {
    console.log("carts", carts);

    setIsCreateOrder(true);
    let readStatePrint = await (
      await cfs.collection("merchants").doc(`${idMerchant}`).get()
    ).data();
    console.log("carts", carts);
    let query = cfs
      .collection("/orders")
      .doc(`${idMerchant}`)
      .collection("myOrders");
    let autoID = query.doc().id;
    query
      .doc(autoID)
      .set({
        id: autoID,
        items: carts.map((i) => ({ ...i, docId: autoID })),
        status: { ...readStatePrint }.autoPrint ? "new" : "pendingNew",
        table: Number(tableNumber),
        queue: 0,
        createDate: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((d) => {
        setSuccess(true);
        console.log("success");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  console.log(carts);
  return (
    <Layout className="layout">
      <Button
        className="fix-widget-back"
        type="link"
        icon={<LeftOutlined />}
        onClick={() => {
          history.push(`/${idMerchant}/${tableNumber}`);
        }}
      />
      <div>
        <Header
          style={{
            backgroundColor: "white",
            boxShadow: "0px 0px 20px 1px rgba(41,41,41,.25)",
            height: 65,
            position: "fixed",
            width: "100%",
            zIndex: 1,
          }}
        >
          <Row>
            <Col span={24} style={{ textAlign: "center", marginTop: 17 }}>
              <Title level={4}>รายการอาหารที่สั่ง</Title>
            </Col>
          </Row>
        </Header>
      </div>
      <Content style={{ marginTop: 80, padding: 13 }}>
        <Card
          title={<Title level={5}>รายการ</Title>}
          extra={<Title level={5}>{`โต๊ะ ${tableNumber}`}</Title>}
          bordered={false}
          style={{
            width: "100%",
            boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
            borderRadius: 4,
            // fontWeight: "bold",
            marginBottom: 13,
          }}
        >
          {carts.map((res: IItemCart, index: number) => {
            return (
              <div key={index}>
                <Row justify="space-between" key={index}>
                  <Col>
                    {" "}
                    <Title
                      style={{ lineHeight: "32px", margin: "0px" }}
                      level={5}
                    >{`${numberWithCommas(res.amount)}x ${
                      res.itemName
                    } (${numberWithCommas(res.price)})`}</Title>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Title
                          style={{ lineHeight: "32px", margin: "0px" }}
                          type="secondary"
                          level={5}
                        >{`${numberWithCommas(
                          (res.price + res.totalOption) * res.amount
                        )}฿`}</Title>
                      </Col>
                      <Col>
                        <Button
                          // type="dashed"
                          onClick={() => removeItem(index)}
                          type="link"
                          shape="circle"
                          icon={<DeleteOutlined />}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <p style={{ color: "gray" }}>
                  {res.options.map(
                    (option: IChoiceValue) => `${option.name}(${option.price}) `
                  )}
                  {res.note !== "" ? res.note : ""}
                </p>
              </div>
            );
          })}
          <Divider style={{ margin: 5 }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
              fontSize: 16,
              fontWeight: 900,
            }}
          >
            <Typography style={{ color: "#ff4d4f" }}>รวมทั้งหมด</Typography>
            <Typography style={{ color: "#ff4d4f" }}>
              {numberWithCommas(
                carts.reduce(
                  (previousValue: number, currentValue: IItemCart) => {
                    let price =
                      currentValue.amount *
                      (currentValue.price + currentValue.totalOption);
                    return previousValue + price;
                  },
                  0
                )
              )}
            </Typography>
          </div>
        </Card>
      </Content>
      <div
        style={{
          boxShadow: "0px -1px 20px 2px rgba(41,41,41,.25)",
          width: "100%",
          height: 69,
          bottom: 0,
          position: "fixed",
          padding: 10,
        }}
      >
        <Button
          onClick={createOrder}
          type="primary"
          disabled={isCreateOrder || carts.length === 0}
          size="middle"
          style={{
            background: !isCreateOrder && carts.length > 0 ? "black" : "gray",
            borderColor: !isCreateOrder && carts.length > 0 ? "black" : "gray",
            width: "100%",
            height: 50,
            borderRadius: 5,
          }}
        >
          <Title level={4} style={{ color: "white", marginTop: 5 }}>
            สั่งอาหาร
          </Title>
        </Button>
      </div>
      <Modal
        cancelButtonProps={{ style: { display: "none" } }}
        centered
        visible={Success}
        onCancel={() => handleClickOpen()}
        footer={[
          <Button
            htmlType="submit"
            type="primary"
            key="submit"
            onClick={() => handleClickOpen()}
            style={{ backgroundColor: "#4cd137", borderColor: "#4cd137" }}
          >
            ตกลง
          </Button>,
        ]}
      >
        <div style={{ textAlign: "center" }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width={200}
          />
          <Title level={4}>สั่งอาหารเรียบร้อย</Title>
        </div>
      </Modal>
    </Layout>
  );
}

export default CustomerCart;
