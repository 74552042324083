import React, { useState, useEffect, useRef } from "react";
import { Badge, Avatar, Input, Typography, Layout, Select, Spin } from "antd";
import { SearchOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { cfs } from "../../firebase/firebase";
import { IItem } from "../../models/Item";
import { IItemCart } from "../../models/ItemCart";
// import { ModalOptions } from "./components/index";
import { ModalOptions, OrdersSelect } from "../../components";
import { useCarts, groupBy } from "../../utility";
import { Link } from "react-router-dom";
import { IChoiceGroup } from "../../models/choiceGroup";
import "./customerOrder.css";

const { Header, Content } = Layout;

const { Title } = Typography;

interface ParamTypes {
  idMerchant: string;
  tableNumber: string;
}

const CustomerOrder = () => {
  const history = useHistory();
  let { idMerchant, tableNumber } = useParams<ParamTypes>();
  const Ref = useRef<Array<any>>([]);
  const RefModal = useRef<any>(null);
  const [carts, setCarts] = useCarts<Array<IItemCart>>();
  const [products, setProducts] = useState<object>({});
  const [currentUser, setCurrentUser] = useState<any>({});
  const [tableNo, setTableNo] = useState(-1);
  const [selectCategory, setSelectCategory] = useState(-1);
  const [count, setCount] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [viewCart, setViewCart] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [choiceGroups, setChoiceGroups] = useState<IChoiceGroup[]>([]);

  const [dataItem, setDataItem] = useState<IItem>({
    productId: "",
    itemName: "",
    price: 0,
    category: "",
    imageUrl: "",
    options: [],
    choices: [],
  });

  const scrollToRefObject = (ref: any, val: number) => {
    // console.log('ref :>> ', ref);
    if (ref && ref.offsetTop) {
      if (val == 0) {
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, ref.offsetTop);
      }
    } else if (val == 0) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      if (!viewCart) handleScroll();
    };
  }, [products]);

  useEffect(() => {
    if (idMerchant) {
      setLoading(true);
      cfs
        .collection("merchants")
        .doc(idMerchant)
        .get()
        .then((doc: any) => {
          let tmpCurrentUser: any = doc.data();
          if (
            Number(tableNumber) >= 0 &&
            Number(tableNumber) <= Number(tmpCurrentUser.maxTable)
          ) {
            document.title = `${tmpCurrentUser.merchantNameTH} | ${tmpCurrentUser.merchantNameEN}`;
            setCurrentUser(tmpCurrentUser);
            setTableNo(Number(tableNumber));
            setLoading(false);
          } else {
            history.push("/");
          }
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [idMerchant]);

  useEffect(() => {
    let unsubscribe: any;
    if (idMerchant) {
      setLoading(true);
      unsubscribe = cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .collection("products")
        .onSnapshot({
          next: async (snapshot) => {
            if (snapshot.size) {
              let tmpData: any = await Promise.all(
                snapshot.docs.map(async (i) => await i.data())
              );
              tmpData = tmpData.filter(
                (item: any) => item.active === true || item.active === undefined
              );
              let tmpIsSuggest: any = tmpData.filter(
                (item: any) => item.isSuggest === true
              );
              let tmpProducts: any = {};
              if (tmpIsSuggest.length > 0) {
                tmpProducts = { เมนูแนะนำ: [...tmpIsSuggest], ...tmpProducts };
              }

              tmpProducts = { ...tmpProducts, ...groupBy(tmpData, "category") };
              // console.log("tmpProducts :>> ", tmpProducts);
              setProducts(tmpProducts);
              setSelectCategory(0);
              setError("");
              setLoading(false);
            } else {
              setProducts([]);
              setError("");
              setLoading(false);
            }
          },
          error: (error) => {
            console.log(error);
            setError(error.message);
            setLoading(false);
          },
        });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [idMerchant]);

  useEffect(() => {
    let unsubscribe: any;
    if (idMerchant) {
      setLoading(true);
      unsubscribe = cfs
        .collection("merchants")
        .doc(`${idMerchant}`)
        .collection("choiceGroups")
        .onSnapshot({
          next: async (snapshot) => {
            if (snapshot.size) {
              let pros: IChoiceGroup[] = await Promise.all(
                snapshot.docs.map(async (i: any) => {
                  const data = i.data();
                  const choices = await Promise.all(
                    data.choices.map(async (choice: any) =>
                      (await choice.get()).data()
                    )
                  );
                  return { ...data, choices: choices };
                })
              );
              // console.log(pros);
              setChoiceGroups(pros);
              setError("");
              setLoading(false);
            } else {
              setChoiceGroups([]);
              setError("");
              setLoading(false);
            }
          },
          error: (error) => {
            console.log(error);
            setError(error.message);
            setLoading(false);
          },
        });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [idMerchant]);

  const handleSelected = (val: number) => {
    // console.log("val : ", val);
    setSelectCategory(val);
    scrollToRefObject(Ref.current[val], val);
  };

  const handleScroll = () => {
    // console.log("object :>> ", selectCategory);
    let scrollTop: any = document.documentElement.scrollTop;
    Ref.current.map((target: any, index: number) => {
      if (target && target.offsetTop != null) {
        let offsetTop = target.offsetTop - 100;
        let offsetHeight = target.offsetHeight;
        if (scrollTop > offsetTop && scrollTop < offsetTop + offsetHeight) {
          setSelectCategory(index);
        }
        //  else {
        //   setSelectCategory(0);
        // }
      }
    });
  };

  const customModal = (data: IItem) => {
    setCount((count: number) => count + 1);
    setVisible(true);
    setDataItem(data);
  };

  const addItem = (data: IItemCart) => {
    setCarts((carts: Array<IItemCart>) => [...carts, data]);
    setVisible(false);
  };

  return (
    <Layout>
      <Badge count={carts.length} className="fix-widget-badge"></Badge>

      <Link to={`/cart/${idMerchant}/${tableNumber}`}>
        <Avatar
          className="fix-widget"
          size={50}
          icon={<ShoppingCartOutlined />}
        ></Avatar>
      </Link>

      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          background: "white",
          boxShadow: "0px 0px 8px 1px rgba(41,41,41,.25)",
          height: "85px",
          padding: "0px",
          lineHeight: "unset",
        }}
      >
        <div style={{ textAlign: "center", marginTop: "12px" }}>
          <Title level={4} style={{ margin: "auto" }}>
            {currentUser.merchantNameTH} - โต๊ะ {tableNo > -1 ? tableNo : "-"}
          </Title>
        </div>
        <div
          style={{
            padding: "5px 15px 0px 15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Input
            className="customInput"
            placeholder="ค้นหา"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<SearchOutlined />}
            style={{
              width: "65%",
              borderRadius: "20px",
              backgroundColor: "#f5f5f5",
              border: "unset",
            }}
          />
          <Select
            className="customSelect"
            style={{ width: "35%", marginLeft: "6px" }}
            id="customSelect"
            // defaultValue={selectCategory}
            value={selectCategory}
            onChange={(e) => handleSelected(e)}
          >
            {Object.keys(products).length > 0 ? (
              Object.keys(products).map((category, index) => (
                <Select.Option key={`select-${index}`} value={index}>
                  {category}
                </Select.Option>
              ))
            ) : (
              <Select.Option key={`select--1`} value={-1}>
                ไม่ทราบ
              </Select.Option>
            )}
          </Select>
        </div>
      </Header>

      <Content style={{ background: "#fff" }}>
        <div
          style={{
            // padding: "85px 15px 0px 15px",
            padding: "95px 15px 0px 15px",
            overflowY: "auto",
            minHeight: "100vh",
          }}
        >
          <Spin spinning={loading}>
            <OrdersSelect
              products={products}
              search={search}
              Ref={Ref}
              customModal={customModal}
            />
          </Spin>
        </div>
        {/* <button onClick={e => createProduct()}>เพิ่ม</button> */}
      </Content>
      {visible ? (
        <ModalOptions
          dataItem={dataItem}
          choiceGroups={choiceGroups}
          visible={visible}
          handleCancel={setVisible}
          addItem={addItem}
        />
      ) : null}
    </Layout>
  );
};

export default CustomerOrder;
