import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Typography,
  Row,
  Col,
  Card,
  Divider,
  Switch,
  Button,
  Modal,
  Spin,
  DatePicker,
  Space,
  notification,
  ConfigProvider,
  Badge,
  Empty,
} from "antd";
import "./adminManagement.css";
import {
  CloseOutlined,
  CheckOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "moment/locale/th";
import locale from "antd/lib/locale/th_TH";
import { Navbar, ModalAddProduct, ModalAddItem } from "./components";
import firebase from "firebase/app";
import { BASE_URL } from "../../config/config";

import { auth, cfs } from "../../firebase/firebase";
import { IItemCart, ItemCart } from "../../models/ItemCart";
import { IMerchant } from "../../models/merchant";
import { IItem } from "../../models/Item";

import { groupBy, numberWithCommas } from "../../utility";
import { ModalOptions, RenderItems } from "../../components";
import { IChoiceValue } from "../../models/choiceValue";
// import ModalPrint from "./components/modalPrint";
const { confirm } = Modal;
const { Title, Text } = Typography;

const AdminManagement = ({ currentUser }: any) => {
  const [products, setProducts] = useState<object>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [disablePrintButton, setDisablePrintButton] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [checked, setChecked] = useState<string>("Auto");
  const [checkedState, setCheckedState] = useState<boolean>(false);
  const [newOrders, setNewOrders] = useState<Array<IItemCart>>([]);
  const [pendingOrders, setPendingOrders] = useState<Array<object>>([]);
  const [originalPendingOrders, setOriginalPendingOrders] = useState<
    Array<object>
  >([]);
  const [successOrders, setSuccessOrders] = useState<Array<IItemCart>>([]);
  const [productsCart, setProductsCart] = useState<Array<IItemCart>>([]);
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState<any>(moment().endOf("day").toDate());
  const [search, setSearch] = useState<string>("");
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (currentUser.merchantNameTH) {
      // console.log("currentUser :>> ", currentUser);
      document.title = `${currentUser.merchantNameTH} | ${currentUser.merchantNameEN}`;
      setCheckedState(currentUser.autoPrint);
      setLoading(false);
    }
  }, [currentUser.merchantNameTH]);

  const [dataItem, setDataItem] = useState<IItem>({
    productId: "",
    itemName: "",
    price: 0,
    category: "",
    imageUrl: "",
    options: [],
    choices: [],
  });

  const [visibleModalOption, setVisibleModalOption] = useState<boolean>(false);

  const createOrder = async (tableNumber: string) => {
    console.log("createOrder", productsCart);
    let query = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders");
    let autoID = query.doc().id;
    query
      .doc(autoID)
      .set({
        id: autoID,
        items: productsCart,
        status: "new",
        table: Number(tableNumber),
        queue: 0,
        createDate: firebase.firestore.Timestamp.fromDate(new Date()),
      })
      .then((d) => {
        console.log("success");
        setProductsCart([]);
        setOpenModalAddItemToBill(false);
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      });
  };

  const addItemCart = (item: IItemCart) => {
    console.log(`addItemCart`, item);
    setProductsCart((items: Array<IItemCart>) => [...items, item]);
    setVisibleModalOption(false);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  const loadProducts = async () => {
    if (currentUser.merchantId != "")
      cfs
        .collection("merchants")
        .doc(`${currentUser.merchantId}`)
        .collection("products")
        .get()
        .then((doc) => {
          let pros = doc.docs.map((i) => i.data());

          setProducts(groupBy(pros, "category"));
          // setProducts(pros);
        })
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
  };

  useEffect(() => {
    if (!currentUser.merchantId) return;
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .where("status", "in", ["new", "pendingNew"])
      .onSnapshot({
        next: async (snapshot) => {
          // console.log(snapshot.size);
          if (snapshot.size) {
            let newOr: any = await Promise.all(
              snapshot.docs.map(async (i) => await i.data())
            );

            setNewOrders(newOr);
          } else {
            // it's empty
            setNewOrders([]);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId]);

  useEffect(() => {
    if (!currentUser.merchantId) return;
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .where("status", "in", ["pending", "pendingPrint"])
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let pendingOr = await Promise.all(
              snapshot.docs.map(async (i) => await i.data())
            );

            // console.log(pendingOr);
            // console.log(prepareBillRender(pendingOr, "table"));
            setPendingOrders(prepareBillRender(pendingOr, "table"));
            setOriginalPendingOrders(pendingOr);
          } else {
            // it's empty
            setPendingOrders([]);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId]);

  useEffect(() => {
    if (!currentUser.merchantId) return;

    setLoading(true);
    // console.log("x1 : ", startDate);
    // console.log("x2 : ", endDate);
    let unsubscribe = cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .where("status", "==", "success")
      .where("createDate", ">", startDate)
      .where("createDate", "<=", endDate)
      .onSnapshot({
        next: async (snapshot) => {
          if (snapshot.size) {
            let successOr = await Promise.all(
              snapshot.docs.map(async (i) => await i.data())
            );

            // console.log("nai : ", successOr.length);

            setSuccessOrders(prepareBillRender(successOr, "billId"));
            setLoading(false);
          } else {
            // it's empty
            setSuccessOrders([]);
            setLoading(false);
          }
        },
        error: (error) => {
          console.log(error);
          setError(error.message);
          setLoading(false);
        },
      });

    return () => {
      unsubscribe();
    };
  }, [currentUser.merchantId, startDate, endDate]);

  const prepareBillRender = (pendingOr: Array<any>, key: string) => {
    let bills = groupBy(pendingOr, key);
    return Object.keys(bills).map((table) => {
      return bills[table].reduce(
        (previousValue: any, currentValue: any, index: number) => {
          if (currentValue.status === "pending") {
            console.log("previousValue :>> ", previousValue);
            console.log("currentValue :>> ", currentValue);
          }
          let newItem = [...previousValue.items, ...currentValue.items].map(
            (i) => {
              // console.log("i :>> ", i);
              let price =
                Number(i.price) +
                (i.option === undefined
                  ? 0
                  : i.option.price === undefined
                  ? 0
                  : Number(i.option.price));
              return {
                ...i,
                total: i.amount * (price + i.totalOption),
              };
            }
          );

          let total = newItem.reduce((p, c) => p + c.total, 0);
          if (index === 0) {
            return {
              ...previousValue,
              createDate: currentValue.createDate,
              table: currentValue.table,
              billId:
                currentValue.billId === undefined ? "" : currentValue.billId,
              total: total,
              items: newItem,
              status: currentValue.status,
              docsId: [...previousValue.docsId, currentValue.id],
            };
          } else {
            return {
              ...previousValue,
              table: currentValue.table,
              billId:
                currentValue.billId === undefined ? "" : currentValue.billId,
              total: total,
              items: newItem,
              status: currentValue.status,
              docsId: [...previousValue.docsId, currentValue.id],
            };
          }
        },
        { items: [], docsId: [] }
      );
    });
  };

  const customModal = (data: IItem) => {
    setVisibleModalOption(true);
    setDataItem(data);
  };

  const removeItemFromBill = (item: object) => {
    console.log("item", item);
    console.log("itemId : ", item["id"]);
    console.log("itemDocId : ", item["docId"]);
    console.log("rm2 : ", originalPendingOrders);
    const doc: any = originalPendingOrders.find(
      (i: any) => i.id === item["docId"]
    );
    console.log("doc : ", doc);

    let newItem = doc.items.filter((i: any) => i["id"] !== item["id"]);
    console.log("newItem : ", newItem);

    let query = cfs
      .collection("orders")
      .doc(`${currentUser.merchantId}`)
      .collection("myOrders")
      .doc(`${item["docId"]}`);

    if (newItem.length > 0) {
      query
        .update({
          items: newItem,
        })
        .then((i) => console.log(i))
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    } else {
      query
        .delete()
        .then((i) => console.log(i))
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    }
  };

  const onChange = async (checked: boolean) => {
    if (checked === true) {
      setChecked("Auto");
      setCheckedState(true);
      cfs
        .collection("/merchants")
        .doc(`${currentUser.merchantId}`)
        .update({ autoPrint: true })
        .then((d) => {
          cfs
            .collection("/orders")
            .doc(`${currentUser.merchantId}`)
            .collection("/myOrders")
            .where("status", "in", ["new", "pendingNew"])
            .get()
            .then(async (orders) => {
              let tmpOrders = await Promise.all(
                orders.docs.map(async (item: any) => await { ...item.data() })
              );
              console.log("set autoPrint: true");
              console.log("tmpOrders", tmpOrders);
              tmpOrders.map((item: any) => {
                print(`order-${item.id}`, item.id);
              });
            })
            .catch((e) => {
              console.log(e);
              setError(e.message);
            });
        })
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    } else {
      setCheckedState(false);
      cfs
        .collection("/merchants")
        .doc(`${currentUser.merchantId}`)
        .update({ autoPrint: false })
        .then((d) => console.log("set autoPrint: false"))
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    }
  };

  function showConfirm(table: string) {
    console.log("table", table);
    confirm({
      title: "คุณแน่ใจหรือไม่ว่าต้องการพิมพ์ ?",
      icon: <ExclamationCircleOutlined />,
      centered: true,

      onOk() {
        console.log("OK");
        closeBill(table);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function showConfirmPrintAgain(table: string) {
    console.log("table", table);
    confirm({
      title: "คุณแน่ใจหรือไม่ว่าต้องการพิมพ์ใบเสร็จอีกครั้ง ?",
      icon: <ExclamationCircleOutlined />,
      centered: true,

      onOk() {
        console.log("OK : ", table);

        const batch = cfs.batch();
        let order: any = successOrders.find(
          (order) => `${order["table"]}` === table
        );
        console.log("order", order);
        order["docsId"].forEach(
          (id: string, index: number, array: Array<any>) => {
            const ref = cfs
              .collection("orders")
              .doc(`${currentUser.merchantId}`)
              .collection("myOrders")
              .doc(id);
            batch.update(ref, {
              status: "pendingPrint",
            });
            if (array.length - 1 === index) {
              batch
                .commit()
                .then((i) => console.log("print again success"))
                .catch((e) => {
                  console.log(e);
                  setError(e.message);
                });
              console.log("update BIll");
            }
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const updateStatus = (id: string, status: string) => {
    cfs
      .collection("/orders")
      .doc(`${currentUser.merchantId}`)
      .collection("/myOrders")
      .doc(`${id}`)
      .update({ status: status })
      .then((d) => {
        console.log("update success");
        setDisablePrintButton(false);
      })
      .catch((e) => {
        console.log(e.message);
        setError(e.message);
        setDisablePrintButton(false);
      });
  };

  const print = (element: any, id: string) => {
    setDisablePrintButton(true);
    console.log("print");
    updateStatus(id, "new");
  };

  const closeBill = async (tableNumber: string) => {
    // Get a new write batch
    const batch = cfs.batch();
    let billId = "00001";
    const query = cfs.collection("merchants").doc(`${currentUser.merchantId}`);

    let merchantInfo = await query.get();
    let currentBill = { ...merchantInfo.data() }.currentBill;
    let newBill = currentBill + 1;

    let order: any = pendingOrders.find(
      (order) => `${order["table"]}` === tableNumber
    );
    order["docsId"].forEach((id: string, index: number, array: Array<any>) => {
      const ref = cfs
        .collection("orders")
        .doc(`${currentUser.merchantId}`)
        .collection("myOrders")
        .doc(id);
      batch.update(ref, {
        billId:
          `${moment(new Date()).format("YYYYMMDo")}` +
          `${newBill}`.padStart(4, "0"),
        status: "pendingPrint",
      });
      if (array.length - 1 === index) {
        batch.update(query, { currentBill: newBill });
        batch
          .commit()
          .then((i) => console.log(i))
          .catch((e) => {
            console.log(e);
            setError(e.message);
          });
        console.log("update BIll");
      }
    });
  };

  const [openModalAddItemToBill, setOpenModalAddItemToBill] = useState<boolean>(
    false
  );

  const [tableSelect, setTableSelect] = useState<string>("");

  const handleOpenModalAddItemToBill = (table: string) => {
    setTableSelect(table);
    setOpenModalAddItemToBill(true);
  };
  const handleCloseModalAddItemToBill = () => {
    setTableSelect("");
    setProductsCart([]);
    setOpenModalAddItemToBill(false);
  };

  const onChangeDate = (dates: any, dateStrings: any) => {
    // console.log("From: ", dates[0], ", to: ", dates[1]);
    // console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    if (dates && dates[0] && dates[1]) {
      // console.log('dates : ', dates)
      // console.log('dateStrings : ', dateStrings)
      let tmpStartDate = dates[0].startOf("day").toDate();
      let tmpEndDate = dates[1].endOf("day").toDate();
      // console.log('tmpStartDate : ', tmpStartDate)
      // console.log('tmpEndDate : ', tmpEndDate)
      setStartDate(tmpStartDate);
      setEndDate(tmpEndDate);

      // let tmpSaveDate = new Date();
      // localStorage.setItem("startDate", tmpStartDate);
      // localStorage.setItem("endDate", tmpEndDate);
      // localStorage.setItem("saveDate", tmpSaveDate.toString());
    } else {
      setError("วันที่ไม่ถูกต้อง");
    }
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล.." spinning={loading}>
      <div className="site-card-wrapper">
        {/* <ModalPrint visible={openPrint} handleCancel={setOpenPrint} /> */}
        <Row gutter={[16, 16]}>
          {width < 992 ? (
            <Col xs={24} style={{ textAlign: "right" }}>
              <ConfigProvider locale={locale}>
                <DatePicker.RangePicker
                  // value={[moment(startDate,'DD-MM-YYYY'), moment(endDate, 'YYYY-MM-DD')]}
                  format="ll"
                  value={[moment(startDate), moment(endDate)]}
                  ranges={{
                    วันนี้: [moment(), moment()],
                    "3 วันที่แล้ว": [moment().subtract(3, "d"), moment()],
                    "5 วันที่แล้ว": [moment().subtract(5, "d"), moment()],
                    อาทิตย์นี้: [
                      moment().startOf("isoWeek"),
                      moment().endOf("isoWeek"),
                    ],
                    เดือนนี้: [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "3 เดือนที่แล้ว": [moment().subtract(3, "M"), moment()],
                    // '6 เดือนที่แล้ว': [moment().subtract(6, 'M'), moment()],
                    ปีที่แล้ว: [moment().subtract(1, "y"), moment()],
                  }}
                  onChange={onChangeDate}
                />
              </ConfigProvider>
            </Col>
          ) : null}
          <Col xs={24} sm={24} md={12} lg={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4} style={{ display: "inline-block" }}>
                ออเดอร์ใหม่
              </Title>
              <div>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={onChange}
                  checked={checkedState}
                />
                {checkedState ? (
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: 17,
                      fontWeight: "bold",
                      marginLeft: 7,
                    }}
                  >
                    {checked}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      display: "inline-block",
                      fontSize: 17,
                      marginLeft: 7,
                      fontWeight: "bold",
                      opacity: 0.2,
                    }}
                  >
                    {checked}
                  </Typography>
                )}
              </div>
            </div>

            <hr
              style={{
                color: "#0984e3",
                height: 3,
                backgroundColor: "#0984e3",
                borderWidth: 0,
                borderRadius: 10,
              }}
            ></hr>
            <Card
              bordered={false}
              className="card-shadow"
              style={{ height: "82vh", overflowY: "auto" }}
            >
              {newOrders.length === 0 && (
                <Empty
                  description={<Text type="secondary">ไม่มีรายการ</Text>}
                />
              )}
              {newOrders.map((order, indexCard) => (
                <div key={`div-${indexCard}`}>
                  <Card
                    key={`Card-${indexCard}`}
                    title={<Title level={5}>{`โต๊ะ ${order["table"]}`}</Title>}
                    extra={<Title level={5}>{`คิวที่ ${indexCard + 1}`}</Title>}
                    bordered={true}
                    style={{
                      width: "100%",
                      boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
                      borderRadius: 4,
                      // fontWeight: "bold",
                      marginBottom: 13,
                    }}
                  >
                    {/* <RenderItems
                      order={order["items"]}
                      remove={false}
                      removeItem={() => {}}
                    /> */}

                    {order["items"].map((res: IItemCart, index: number) => (
                      <Row
                        justify="space-between"
                        key={`Row-${index}${indexCard}`}
                      >
                        <Col>
                          {" "}
                          <Title
                            level={5}
                          >{`${res.amount}x ${res.itemName} (${res.price})`}</Title>
                          <p style={{ color: "gray" }}>
                            {res.options.map(
                              (option: IChoiceValue) =>
                                `${option.name}(${option.price})`
                            )}
                            {res.note !== "" ? `*${res.note}` : ""}
                          </p>
                        </Col>
                        <Col>
                          {" "}
                          <Title type="secondary" level={5}>{`${
                            (res.price + res.totalOption) * res.amount
                          }฿`}</Title>
                        </Col>
                      </Row>
                    ))}

                    <Divider style={{ margin: 5 }} />
                    {checkedState === true ? (
                      <div></div>
                    ) : (
                      <Button
                        disabled={disablePrintButton}
                        type="primary"
                        icon={<PrinterOutlined />}
                        style={{ float: "right" }}
                        onClick={() => print(`order-${indexCard}`, order["id"])}
                      >
                        พิมพ์
                      </Button>
                    )}
                  </Card>
                </div>
              ))}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Title level={4} style={{ display: "inline-block" }}>
              พักบิล
            </Title>
            <hr
              style={{
                color: "#fbc531",
                height: 3,
                backgroundColor: "#fbc531",
                borderWidth: 0,
                borderRadius: 10,
              }}
            ></hr>
            <Card
              bordered={false}
              className="card-shadow"
              style={{ height: "82vh", overflowY: "auto" }}
            >
              {pendingOrders.length === 0 && (
                <Empty
                  description={<Text type="secondary">ไม่มีรายการ</Text>}
                />
              )}
              {pendingOrders.map((order, index) => {
                return (
                  <Spin
                    tip="กำลังพิมพ์.."
                    spinning={order["status"] === "pendingPrint"}
                    key={index}
                  >
                    {" "}
                    <Card
                      key={index}
                      title={
                        <Title level={5}>{`โต๊ะ ${order["table"]}`}</Title>
                      }
                      extra={
                        <Button
                          type="dashed"
                          shape="circle"
                          onClick={
                            () => {
                              window.open(
                                `${BASE_URL}/${currentUser.merchantId}/${order["table"]}`,
                                "Data",
                                "height=630,width=400"
                              );
                            }
                            // handleOpenModalAddItemToBill(order["table"])
                          }
                          icon={<PlusOutlined />}
                        />
                      }
                      bordered={true}
                      style={{
                        width: "100%",
                        boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
                        borderRadius: 4,
                        // fontWeight: "bold",
                        marginBottom: 13,
                      }}
                    >
                      <RenderItems
                        order={order["items"]}
                        removeItem={removeItemFromBill}
                        remove={true}
                      />

                      <Divider style={{ margin: 5 }} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginBottom: 10,
                          fontSize: 16,
                          fontWeight: 900,
                        }}
                      >
                        <Typography style={{ color: "#000" }}>
                          รวมทั้งหมด
                        </Typography>
                        <Typography
                          style={{ color: "#000" }}
                        >{`฿${numberWithCommas(order["total"])}`}</Typography>
                      </div>
                      <Button
                        type="primary"
                        icon={<PrinterOutlined />}
                        style={{ float: "right" }}
                        onClick={() => showConfirm(`${order["table"]}`)}
                      >
                        พิมพ์ใบเสร็จ
                      </Button>
                    </Card>
                  </Spin>
                );
              })}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4} style={{ display: "inline-block" }}>
                {`สำเร็จ (${successOrders.length}) (${numberWithCommas(
                  successOrders.reduce(
                    (pre, cur) => pre + Number(cur["total"]),
                    0
                  )
                )} ฿)`}
              </Title>

              {width >= 992 ? (
                <ConfigProvider locale={locale}>
                  <DatePicker.RangePicker
                    // value={[moment(startDate,'DD-MM-YYYY'), moment(endDate, 'YYYY-MM-DD')]}
                    format="ll"
                    value={[moment(startDate), moment(endDate)]}
                    ranges={{
                      วันนี้: [moment(), moment()],
                      "3 วันที่แล้ว": [moment().subtract(3, "d"), moment()],
                      "5 วันที่แล้ว": [moment().subtract(5, "d"), moment()],
                      อาทิตย์นี้: [
                        moment().startOf("isoWeek"),
                        moment().endOf("isoWeek"),
                      ],
                      เดือนนี้: [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "3 เดือนที่แล้ว": [moment().subtract(3, "M"), moment()],
                      // '6 เดือนที่แล้ว': [moment().subtract(6, 'M'), moment()],
                      ปีที่แล้ว: [moment().subtract(1, "y"), moment()],
                    }}
                    onChange={onChangeDate}
                  />
                </ConfigProvider>
              ) : null}
            </div>
            <hr
              style={{
                color: "#4cd137",
                height: 3,
                backgroundColor: "#4cd137",
                borderWidth: 0,
                borderRadius: 10,
              }}
            ></hr>
            <Card
              bordered={false}
              className="card-shadow"
              style={{ height: "82vh", overflowY: "auto" }}
            >
              {successOrders.length === 0 && (
                <Empty
                  description={<Text type="secondary">ไม่มีรายการ</Text>}
                />
              )}
              {successOrders
                .sort(
                  (a, b) => b["createDate"].toDate() - a["createDate"].toDate()
                )
                .map((bill, index) => (
                  <Card
                    key={index}
                    title={
                      <div>
                        <Title
                          level={4}
                          style={{ margin: 0 }}
                        >{`โต๊ะ ${bill["table"]}`}</Title>
                        <Title
                          level={5}
                          style={{ margin: 0, marginTop: 6 }}
                          type="secondary"
                        >
                          เวลาพิมพ์
                        </Title>
                      </div>
                    }
                    extra={
                      <div style={{ textAlign: "right" }}>
                        <Title level={4} style={{ margin: 0 }}>
                          {`เลขที่บิล : ${bill["billId"]}`}
                          <PrinterOutlined
                            style={{ color: "#1890ff" }}
                            onClick={() =>
                              showConfirmPrintAgain(`${bill["table"]}`)
                            }
                          />
                        </Title>
                        <Title
                          level={5}
                          style={{ margin: 0, marginTop: 6 }}
                          type="secondary"
                        >{`${moment(bill["createDate"].toDate()).format(
                          "lll"
                        )}`}</Title>
                      </div>
                    }
                    bordered={false}
                    style={{
                      width: "100%",
                      boxShadow: "0 3px 20px 0 rgba(0,0,0,.12)",
                      borderRadius: 4,
                      fontWeight: "bold",
                      marginBottom: 13,
                    }}
                  >
                    <RenderItems
                      order={bill["items"]}
                      removeItem={(index) => {}}
                      remove={false}
                    />
                    <Divider style={{ margin: 5 }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // marginBottom: 10,
                        fontSize: 16,
                        fontWeight: 900,
                      }}
                    >
                      <Typography style={{ color: "#000" }}>
                        รวมทั้งหมด
                      </Typography>
                      <Typography
                        style={{ color: "#000" }}
                      >{`฿${numberWithCommas(bill["total"])}`}</Typography>
                    </div>
                  </Card>
                ))}
            </Card>
          </Col>
        </Row>
      </div>
      {openModalAddItemToBill ? (
        <ModalAddItem
          choiceGroups={[]}
          products={products}
          productsCart={productsCart}
          customModal={customModal}
          open={openModalAddItemToBill}
          table={tableSelect}
          search={search}
          currentUser={currentUser}
          handleCancel={handleCloseModalAddItemToBill}
          visible={visibleModalOption}
          dataItem={dataItem}
          addItem={addItemCart}
          handleCancelOption={setVisibleModalOption}
          createOrder={createOrder}
        />
      ) : null}
    </Spin>
  );
};

export default AdminManagement;
