import React, { useEffect, useState } from "react";
import CustomerOrder from "../views/customerOrder";
import AdminManagement from "../views/adminManagement";
import CustomerCart from "../views/customerCart";
// import QrcodeScanner from "../views/qrcodeScanner";
import Login from "../views/login";
import Profile from "../views/profile";
import Report from "../views/report";
import Product from "../views/product";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { auth, cfs } from "../firebase/firebase";
import { Redirect } from "react-router";
import { notification } from "antd";
import MainLayout from "../views/layouts/mainLayout";
import { Error404 } from "../views/error";

function Routes() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<any>(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        cfs
          .collection("/merchants")
          .where("users", "array-contains", user.email)
          .onSnapshot({
            next: async (snapshot) => {
              if (snapshot.size) {
                let tmpData = snapshot.docs[0].data();
                let tmpUserData = { ...tmpData };
                delete tmpUserData.users;
                delete tmpUserData.TOKEN_LINE_NOTIFY;
                localStorage.setItem("userData", JSON.stringify(tmpUserData));
                setCurrentUser(tmpUserData);
                setLoading(false);
              } else {
                // it's empty
                localStorage.clear();
                setError("ไม่พบผู้ใช้ โปรดติดต่อผู้ดูแลระบบเพื่อใช้งาน");
                setLoading(false);
                console.log("empty");
              }
            },
            error: (error) => {
              localStorage.clear();
              console.log(error.message);
              setCurrentUser(null);
              setError(error.message);
              setLoading(false);
            },
          });
      } else {
        setCurrentUser(null);
        setError("");
        setLoading(false);
        localStorage.clear()
      }
    });
  }, []);

  useEffect(() => {
    let location = window.location.pathname;
    if (
      error &&
      (location.includes("login") || location.includes("merchants"))
    ) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  const RenderComponent = (Comp: any) => (
    <MainLayout currentUser={currentUser}>
      <Comp currentUser={currentUser} />
    </MainLayout>
  );

  // console.log('currentUser', currentUser)

  if (loading) {
    return <div className="loading" />;
  } else {
    return (
      <Router>
        <Switch>
          {/* <Route
            exact
            path="/"
            render={() =>
              currentUser === null ? (
                <Redirect to="/login" />
              ) : (
                <QrcodeScanner />
              )
            }
          ></Route> */}

          <Route
            exact
            path="/merchant"
            render={() =>
              currentUser === null ? (
                <Redirect to="/login" />
              ) : (
                RenderComponent(AdminManagement)
              )
            }
          ></Route>

          <Route
            exact
            path="/profile"
            render={() =>
              currentUser === null ? (
                <Redirect to="/login" />
              ) : (
                RenderComponent(Profile)
              )
            }
          ></Route>

          <Route
            exact
            path="/report"
            render={() =>
              currentUser === null ? (
                <Redirect to="/login" />
              ) : (
                RenderComponent(Report)
              )
            }
          ></Route>

          <Route
            exact
            path="/product"
            render={() =>
              currentUser === null ? (
                <Redirect to="/login" />
              ) : (
                RenderComponent(Product)
              )
            }
          ></Route>

          <Route
            exact
            path="/login"
            render={() =>
              currentUser !== null ? <Redirect to="/merchant" /> : <Login />
            }
          ></Route>

          <Route exact path="/:idMerchant/:tableNumber">
            <CustomerOrder />
          </Route>

          <Route exact path="/cart/:idMerchant/:tableNumber">
            <CustomerCart />
          </Route>

          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Routes;
