import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/firebase-database";
import "firebase/auth";
import "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyBbExo7_LyRk29lA5u0ctE8fDwi8dHbJnA",
  authDomain: "qr-orders.firebaseapp.com",
  databaseURL: "https://qr-orders.firebaseio.com",
  projectId: "qr-orders",
  storageBucket: "qr-orders.appspot.com",
  messagingSenderId: "420090828361",
  appId: "1:420090828361:web:afb1a8e5467fd419011198",
  measurementId: "G-D6T1ZBTFVC"
};
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const cfs = firebase.firestore();
const rdb = firebase.database();
const str = firebase.storage();

export { auth, cfs, rdb, str };
