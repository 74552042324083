import React, { useEffect, useState, useRef } from "react";
import { Layout, Menu, Typography, Breadcrumb } from "antd";
import {
  UserOutlined,
  TeamOutlined,
  CloseOutlined,
  MenuOutlined,
  ReconciliationOutlined,
  ShoppingOutlined,
  ShopOutlined,
  UserSwitchOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import "moment/locale/th";
import { auth, cfs } from "../../firebase/firebase";
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL } from "../../config/config";

const { Title } = Typography;
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const subMenus = [
  // {
  //   name: "เปิดบิล",
  //   path: "merchant",
  //   icon: <ReconciliationOutlined />,
  // },
  // {
  //   name: "หน้าหลัก",
  //   path: "merchant",
  //   icon: <HomeOutlined />,
  // },
  {
    name: "รายงาน",
    path: "report",
    icon: <ShoppingOutlined />,
  },
  {
    name: "จัดการสินค้า",
    path: "product",
    icon: <ShoppingOutlined />,
  },
  {
    name: "จัดการผู้ใช้",
    path: "profile",
    icon: <ShopOutlined />,
  },
  {
    name: "ออกจากระบบ",
    path: "logout",
    icon: <UserSwitchOutlined />,
  },
];

function MainLayout({ currentUser, children }: any) {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [broken, setBroken] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const [width, setWidth] = useState<number>(0);
  const rootRef = useRef();

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // console.log("user", user);
      if (!user) {
        handleLogout();
      }
    });
  }, []);

  const renderLogo = (className: string) => {
    return (
      <div
        className={className ? className : "logo"}
        onClick={() => {
          setCollapsed(true);
          history.push("/merchant");
        }}
      >
        <Title
          level={4}
          style={{
            color: "white",
            cursor: "pointer",
            margin: className === "logoMobile" ? 0 : "",
          }}
        >
          {currentUser.merchantNameTH}
        </Title>
      </div>
    );
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      localStorage.clear();
    });
  };

  return (
    <div>
      <Layout className="layout" style={{ height: "100%" }}>
        {width < 992 ? (
          <Sider
            trigger={collapsed ? <MenuOutlined /> : <CloseOutlined />}
            breakpoint="md"
            collapsedWidth="0"
            theme="dark"
            // theme={collapsed ? "light" : "dark"}
            collapsible
            // collapsed={true}
            // collapsed={false}
            collapsed={collapsed}
            onBreakpoint={(broken) => {
              console.log(broken);
              setBroken(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log("nai : ", collapsed, type);
              setCollapsed(collapsed);
            }}
          >
            {width < 992 ? renderLogo("logoMobile") : <div className="logo" />}
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[location.pathname.replace("/", "")]}
            >
              <SubMenu
                key="createBill"
                title="เปิดบิล"
                icon={<ReconciliationOutlined />}
              >
                {new Array(
                  currentUser && currentUser.tables !== undefined
                    ? currentUser.tables + 1
                    : 0
                )
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <Menu.Item
                        key={index}
                        style={{ width: 200 }}
                        onClick={() => {
                          setCollapsed(true);
                          window.open(
                            `${BASE_URL}/${currentUser.merchantId}/${index}`,
                            "Data",
                            "height=630,width=400"
                          );
                        }}
                      >{`เปิดบิลโต๊ะ ${index}`}</Menu.Item>
                    );
                  })}
              </SubMenu>
              {subMenus.map((subMenu, index) => {
                return (
                  <Menu.Item
                    key={subMenu.path}
                    icon={subMenu.icon}
                    onClick={(e) => {
                      if (subMenu.name === "ออกจากระบบ") {
                        handleLogout();
                      } else {
                        setCollapsed(true);
                        history.push(subMenu.path);
                      }
                    }}
                  >
                    {subMenu.name}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Sider>
        ) : null}

        <Layout>
          {width >= 992 ? (
            <Header
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {renderLogo("logo")}
              <Menu
                theme="dark"
                mode="horizontal"
                // triggerSubMenuAction="click"
                triggerSubMenuAction="hover"
                selectedKeys={[location.pathname.replace("/", "")]}
              >
                <SubMenu key="createBill" title="เปิดบิล">
                  {new Array(
                    currentUser && currentUser.tables !== undefined
                      ? currentUser.tables + 1
                      : 0
                  )
                    .fill(0)
                    .map((item, index) => {
                      return (
                        <Menu.Item
                          key={index}
                          style={{ width: 200 }}
                          onClick={() => {
                            window.open(
                              `${BASE_URL}/${currentUser.merchantId}/${index}`,
                              "Data",
                              "height=630,width=400"
                            );
                          }}
                        >{`เปิดบิลโต๊ะ ${index}`}</Menu.Item>
                      );
                    })}
                </SubMenu>

                <SubMenu
                  key="SubMenu"
                  icon={
                    <MenuOutlined
                      style={{ fontSize: 23, color: "white", marginLeft: 10 }}
                    />
                  }
                >
                  {subMenus.map((subMenu, index) => {
                    return (
                      <Menu.Item
                        key={subMenu.path}
                        icon={subMenu.icon}
                        onClick={(e) => {
                          if (subMenu.name === "ออกจากระบบ") {
                            handleLogout();
                          } else {
                            let tmp: any = e.key;
                            history.push(subMenu.path);
                          }
                        }}
                      >
                        {subMenu.name}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              </Menu>
            </Header>
          ) : null}

          {/* <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>สถิติการใช้งาน</Breadcrumb.Item>
              <Breadcrumb.Item>ทั้งหมด</Breadcrumb.Item>
            </Breadcrumb> */}

          <Content>
            <div
              style={{
                //   padding: 24,
                minHeight: 360,
                height: "100%",
              }}
            >
              <div className="site-layout-content">{children}</div>
            </div>
          </Content>

          {/* {width >= 1200 ? (
            <Footer
              style={{
                textAlign: "center",
                paddingBottom: 16,
                paddingTop: 16,
              }}
            >
              Powered by ALLDER CORPORATION CO.,LTD.
            </Footer>
          ) : null} */}
        </Layout>
      </Layout>
    </div>
  );
}

export default MainLayout;
