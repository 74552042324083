import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Dropdown,
  Typography,
  Modal,
  Row,
  Col,
  Select,
  Card,
  Checkbox,
  Button,
  notification,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Customerorder from "../../../customerOrder";
import ModalAddProduct from "../../../adminManagement/components/modalAddProduct";
import { IMerchant } from "../../../../models/merchant";
import { auth, cfs } from "../../../../firebase/firebase";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../../config/config";

const { Option } = Select;
const { Header } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

function Navbar(props: any) {
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (error) {
      notification.error({
        message: "เกิดข้อผิดพลาด",
        description: error,
        placement: "bottomRight",
        onClick: () => {
          setError("");
        },
        onClose: () => {
          setError("");
        },
      });
    }
  }, [error]);

  const handleClickOpen = () => {
    setVisible(false);
  };
  const handleClickClose = () => {
    setVisible(false);
  };

  function handleChange(value: string) {
    console.log(`โต๊ะที่  ${value}`);
  }
  function checkbox(e: any) {
    console.log(`checked = ${e.target.checked}`);
  }

  const handleLogout = () => {
    auth.signOut().then(() => {
      localStorage.clear();
    });
  };

  return (
    <div>
      <Layout>
        <Header
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            onClick={() => {
              history.push("/merchant");
            }}
          >
            <Title
              level={2}
              style={{ color: "#fff", marginTop: 8, cursor: "pointer" }}
            >
              Merchant
            </Title>
          </div>
          <Menu theme="dark" mode="horizontal">
            <SubMenu key="createBill" title="เปิดบิล">
              {new Array(props.currentUser.tables + 1)
                .fill(0)
                .map((item, index) => {
                  return (
                    <Menu.Item
                      key={index}
                      style={{ width: 200 }}
                      onClick={() => {
                        window.open(
                          `${BASE_URL}/${props.currentUser.merchantId}/${index}`,
                          "Data",
                          "height=630,width=400"
                        );
                      }}
                    >{`เปิดบิลโต๊ะ ${index}`}</Menu.Item>
                  );
                })}
            </SubMenu>

            <SubMenu
              key="SubMenu"
              icon={
                <MenuOutlined
                  style={{ fontSize: 23, color: "white", marginLeft: 10 }}
                />
              }
            >
              <Menu.Item
                key="merchant"
                onClick={() => {
                  history.push("/merchant");
                }}
              >
                หน้าหลัก
              </Menu.Item>
              <Menu.Item
                key="report"
                onClick={() => {
                  history.push("/report");
                }}
              >
                รายงาน
              </Menu.Item>
              <Menu.Item
                key="product"
                onClick={() => {
                  history.push("/product");
                }}
              >
                จัดการสินค้า
              </Menu.Item>
              <Menu.Item
                key="profile"
                onClick={() => {
                  history.push("/profile");
                }}
              >
                จัดการผู้ใช้
              </Menu.Item>
              <Menu.Item
                key="logout"
                style={{ width: 200 }}
                onClick={() => {
                  handleLogout();
                }}
              >
                ออกจากระบบ
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Header>
      </Layout>

      <Modal
        title="เปิดบิล"
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        width={1000}
        footer={[
          <Button
            htmlType="submit"
            key="cancel"
            onClick={() => handleClickClose()}
          >
            ยกเลิก
          </Button>,
          <Button
            htmlType="submit"
            type="primary"
            key="submit"
            onClick={() => handleClickOpen()}
          >
            ตกลง
          </Button>,
        ]}
      >
        <Row>
          <Col span={12}>
            <Card style={{ height: 400, overflowY: "auto" }} bordered={false}>
              รายการเมนู
              {/* <Customerorder /> */}
            </Card>
          </Col>
          <Col span={12}>
            <Card bordered={false}>
              <Select
                defaultValue="1"
                style={{ width: 250 }}
                onChange={handleChange}
                size={"large"}
              >
                <Option value="1">โต๊ะที่ 1</Option>
                <Option value="2">โต๊ะที่ 2</Option>
                <Option value="3">โต๊ะที่ 3</Option>
                <Option value="4">โต๊ะที่ 4</Option>
                <Option value="5">โต๊ะที่ 5</Option>
                <Option value="6">โต๊ะที่ 6</Option>
              </Select>
              <Checkbox
                onChange={checkbox}
                style={{ display: "block", marginTop: 25 }}
              >
                ลูกค้า walk-in
              </Checkbox>
            </Card>
          </Col>
        </Row>
      </Modal>
      <ModalAddProduct open={false} handleAddProduct={() => {}} />
    </div>
  );
}

export default Navbar;
